import { useLoaderData } from '@remix-run/react'
import {
  type MetaFunction,
  type LoaderFunctionArgs,
  json,
} from '@remix-run/cloudflare'
import { count } from 'drizzle-orm'
import { drizzle } from 'drizzle-orm/d1'
import { visit } from '../../drizzle/schema'
// import { getDB } from '~/db.server'

export const meta: MetaFunction = () => {
  return [
    { title: 'New Remix App' },
    {
      name: 'description',
      content: 'Welcome to Remix! Using Vite and Cloudflare!',
    },
  ]
}

export async function loader({ context }: LoaderFunctionArgs) {
  // const prisma = getDB(context.cloudflare.env.DB)
  const db = drizzle(context.cloudflare.env.DB)
  const response = await fetch('https://dummyjson.com/users/1')
  const user = await response.json()

  // const count = await prisma.visit.count()
  const all = await db.select({ count: count() }).from(visit)
  const { MY_KV } = context.cloudflare.env
  const cache = await MY_KV.get('cache-key')

  return json({
    count: all[0].count,
    // count,
    cache,
    user,
  })
}

export default function Index() {
  const { count, cache, user } = useLoaderData<typeof loader>()

  return (
    <div style={{ fontFamily: 'system-ui, sans-serif', lineHeight: '1.8' }}>
      <h1>Welcome to Remix (with Vite and Cloudflare)</h1>
      <p id="visit">visit count: {count}</p>
      <p>cache: {cache}</p>
      <p>
        user: {user.firstName} {user.lastName}
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://developers.cloudflare.com/pages/framework-guides/deploy-a-remix-site/"
            rel="noreferrer"
          >
            Cloudflare Pages Docs - Remix guide
          </a>
        </li>
        <li>
          <a target="_blank" href="https://remix.run/docs" rel="noreferrer">
            Remix Docs
          </a>
        </li>
      </ul>
    </div>
  )
}
